import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { RcButton } from './RcButton'
import { RcTrans } from '@/components/atoms/RcTrans'

export const ConfirmationDialog: React.FC<{
  id?: string
  message: React.ReactNode | string
  placeholder?: string
  confirmToken?: string
  open: boolean
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  onOk: (event: React.MouseEvent<HTMLButtonElement>) => void
  cancel?: React.ReactNode
  ok?: React.ReactNode
}> = ({
  id,
  placeholder,
  message,
  confirmToken,
  open,
  onCancel,
  onOk,
  cancel,
  ok
}) => {
  const [confirmValue, setConfirmValue] = useState('')

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      disableEscapeKeyDown
      PaperProps={{ elevation: 4 }}
    >
      <DialogContent style={{ maxWidth: 600 }}>
        <Stack direction='column' spacing={3}>
          {message || placeholder || null}

          {confirmToken ? (
            <TextField
              placeholder={placeholder}
              value={confirmValue}
              onChange={evt => setConfirmValue(evt.target.value)}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <RcButton
          id={(id || 'generic') + '-cancel-action'}
          onClick={onCancel}
          variant='contained'
          color='secondary'
        >
          {cancel || <RcTrans i18nKey='shared.cancel' />}
        </RcButton>
        <RcButton
          color='error'
          id={(id || 'generic') + '-confirm-action'}
          onClick={onOk}
          variant='contained'
          disabled={
            !!confirmToken &&
            confirmToken.toLowerCase() !== confirmValue?.toLowerCase()
          }
        >
          {ok || <RcTrans i18nKey='shared.confirm-button' />}
        </RcButton>
      </DialogActions>
    </Dialog>
  )
}
