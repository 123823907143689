'use client'

import { RootRoute } from '@/core/route-keys'
import { useNavigation } from '@/core/hooks/useNavigation'
import { Redirect } from '@/components/pages/CMS/dynamic/Redirect'
import { Login } from '@/components/pages/Site/Login'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export default function LoginPage() {
  const { user } = useFirebase()
  const { getPath } = useNavigation()
  return (
    <>
      {user ? (
        <Redirect
          replace
          path={getPath({ root: RootRoute.Registration })}
          height='50dvh'
        />
      ) : (
        <Login isCreate={false} />
      )}
    </>
  )
}
