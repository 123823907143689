import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import React, { useCallback } from 'react'
import { RcTrans } from '../../../components/atoms/RcTrans'
import { useRcTranslation } from '../useRcTranslation'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'

// from REQUIRED_NETWORK_REGEX on server
export const competitionNetworks: NetworkKind[] = [
  NetworkKind.DISCORD,
  NetworkKind.BATTLE_NET,
  NetworkKind.STEAM,
  NetworkKind.XBOX,
  NetworkKind.PLAYSTATION,
  NetworkKind.FACEBOOK,
  NetworkKind.CHESS_COM,
  NetworkKind.NINTENDO,
  NetworkKind.DOMINION_ONLINE,
  NetworkKind.ORIGIN,
  NetworkKind.EPIC,
  NetworkKind.WIZARDS_OF_THE_COAST,
  NetworkKind.RIOT,
  NetworkKind.RIOT_VALORANT_AMERICAS,
  NetworkKind.SUPERCELL,
  NetworkKind.TETRIO,
  NetworkKind.GARENA,
  NetworkKind.UNITY,
  NetworkKind.STREET_FIGHTER_ID,
  NetworkKind.UBISOFT_ID,
  NetworkKind.ACTIVISION_ID
]

/*
 * Wrapper for getting sorted translations and icons for network kind enum.
 */
export const useNetworkKinds = () => {
  const { t } = useRcTranslation()

  // Hardcoded instead of dynamic so that translator can extract them
  const getNetworkString = useCallback(
    (network?: NetworkKind) => {
      const record = {
        [NetworkKind.MASTERYCODING]: t('networks.MASTERYCODING'),
        [NetworkKind.FROST_GIANT]: t('networks.FROST_GIANT'),
        [NetworkKind.BATTLE_NET]: t('networks.BATTLE_NET'),
        [NetworkKind.CHESS_COM]: t('networks.CHESS_COM'),
        [NetworkKind.DISCORD]: t('networks.DISCORD'),
        [NetworkKind.DOMINION_ONLINE]: t('networks.DOMINION_ONLINE'),
        [NetworkKind.EMAIL]: t('networks.EMAIL'),
        [NetworkKind.EPIC]: t('networks.EPIC'),
        [NetworkKind.FACEBOOK]: t('networks.FACEBOOK'),
        [NetworkKind.WEBSITE]: t('networks.WEBSITE'),
        [NetworkKind.INSTAGRAM]: t('networks.INSTAGRAM'),
        [NetworkKind.LINKEDIN]: t('networks.LINKEDIN'),
        [NetworkKind.NINTENDO]: t('networks.NINTENDO'),
        [NetworkKind.ORIGIN]: t('networks.ORIGIN'),
        [NetworkKind.PLAYSTATION]: t('networks.PLAYSTATION'),
        [NetworkKind.RIOT]: t('networks.RIOT'),
        [NetworkKind.RIOT_VALORANT_AMERICAS]: t('networks.RIOTAMERICAS'),
        [NetworkKind.RIOT_VALORANT_ASIA]: t('networks.RIOTASIA'),
        [NetworkKind.RIOT_VALORANT_EUROPE]: t('networks.RIOTEUROPE'),
        [NetworkKind.STEAM]: t('networks.STEAM'),
        [NetworkKind.SUPERCELL]: t('networks.SUPERCELL'),
        [NetworkKind.TETRIO]: t('networks.TETRIO'),
        [NetworkKind.TWITCH]: t('networks.TWITCH'),
        [NetworkKind.TWITTER]: t('networks.TWITTER'),
        [NetworkKind.WIZARDS_OF_THE_COAST]: t('networks.WIZARDS_OF_THE_COAST'),
        [NetworkKind.XBOX]: t('networks.XBOX'),
        [NetworkKind.UNITY]: t('networks.UNITY'),
        [NetworkKind.YOUTUBE]: t('networks.YOUTUBE'),
        [NetworkKind.BOY_SCOUTS_OF_AMERICA]: t(
          'networks.BOY_SCOUTS_OF_AMERICA'
        ),
        [NetworkKind.ID_ME]: t('networks.ID_ME'),
        [NetworkKind.GARENA]: t('networks.GARENA'),
        [NetworkKind.TIKTOK]: t('networks.TIKTOK'),
        [NetworkKind.ACTIVISION_ID]: t('networks.ACTIVISION_ID'),
        [NetworkKind.UBISOFT_ID]: t('networks.UBISOFT_ID'),
        [NetworkKind.STREET_FIGHTER_ID]: t('networks.STREETFIGHERID')
      } as Partial<Record<NetworkKind, string>>

      return (record[network!] || t('networks.unknown')) as string
    },
    [t]
  )

  const getNetworkIcon = useCallback((network?: NetworkKind): IconProp => {
    const record = {
      [NetworkKind.MASTERYCODING]: ['fal', 'graduation-cap'],
      [NetworkKind.FROST_GIANT]: ['fal', 'ice-cream'],
      [NetworkKind.BATTLE_NET]: ['fab', 'battle-net'],
      [NetworkKind.CHESS_COM]: ['fal', 'chess-pawn'],
      [NetworkKind.DISCORD]: ['fab', 'discord'],
      [NetworkKind.DOMINION_ONLINE]: ['fab', 'dominion'] as any,
      [NetworkKind.EMAIL]: ['fal', 'envelope'],
      [NetworkKind.EPIC]: ['fab', 'epic'] as any,
      [NetworkKind.FACEBOOK]: ['fab', 'facebook'],
      [NetworkKind.WEBSITE]: ['fal', 'external-link'],
      [NetworkKind.INSTAGRAM]: ['fab', 'instagram'] as any,
      [NetworkKind.LINKEDIN]: ['fab', 'linkedin-in'],
      [NetworkKind.NINTENDO]: ['fab', 'nintendo'] as any,
      [NetworkKind.ORIGIN]: ['fab', 'origin'] as any,
      [NetworkKind.PLAYSTATION]: ['fab', 'playstation'],
      [NetworkKind.RIOT]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOT_VALORANT_AMERICAS]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOT_VALORANT_ASIA]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOT_VALORANT_EUROPE]: ['fab', 'riot-games'] as any,
      [NetworkKind.STEAM]: ['fab', 'steam'],
      [NetworkKind.SUPERCELL]: ['fab', 'supercell'] as any,
      [NetworkKind.TETRIO]: ['fab', 'tetris'] as any,
      [NetworkKind.TWITCH]: ['fab', 'twitch'] as any,
      [NetworkKind.TWITTER]: ['fab', 'x-twitter'],
      [NetworkKind.WIZARDS_OF_THE_COAST]: ['fab', 'wizards-of-the-coast'],
      [NetworkKind.XBOX]: ['fab', 'xbox'],
      [NetworkKind.UNITY]: ['fab', 'unity'],
      [NetworkKind.YOUTUBE]: ['fab', 'youtube'] as any,
      [NetworkKind.BOY_SCOUTS_OF_AMERICA]: [
        'fab',
        'boy-scouts-of-america'
      ] as any,
      [NetworkKind.ID_ME]: ['fab', 'id-me'] as any,
      [NetworkKind.GARENA]: ['fab', 'garena'] as any,
      [NetworkKind.TIKTOK]: ['fab', 'tiktok'] as any,
      [NetworkKind.ACTIVISION_ID]: ['fab', 'activision'] as any,
      [NetworkKind.UBISOFT_ID]: ['fab', 'ubisoft'] as any,
      [NetworkKind.STREET_FIGHTER_ID]: ['fab', 'streetfighter'] as any
    } as Partial<Record<NetworkKind, IconProp>>

    return (record[network!] as IconProp) || ['fal', 'sync']
  }, [])

  const getNetworkKindDisclaimer = useCallback((network: NetworkKind) => {
    const record = {
      [NetworkKind.ID_ME]: (
        <RcTrans i18nKey='networks.ID_ME-disclaimer'>
          before
          <Link href='https://help.id.me' target='_new' rel='noopener'>
            text
          </Link>
          after
        </RcTrans>
      )
    } as Partial<Record<NetworkKind, React.ReactNode>>
    return record[network]
  }, [])

  return {
    getNetworkString,
    getNetworkKindDisclaimer,
    getNetworkIcon
  }
}
