import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Box from '@mui/material/Box'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { forwardRef, useState } from 'react'
import { ButtonProps } from '@mui/material/Button'
import { ConfirmationDialog } from './ConfirmationDialog'
import { RcButton } from './RcButton'
import { RcIconButton } from './RcIconButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useApiError } from '@/core/hooks/useApiError'

export interface ConfirmingButtonProps extends Omit<ButtonProps, 'action'> {
  message: React.ReactNode | string
  buttonName?: string | React.ReactNode
  buttonSize?: 'small' | 'medium' | 'large'
  icon?: IconProp
  placeholder?: string
  confirmToken?: string
  fullWidth?: boolean // default true
  TooltipProps?: Omit<TooltipProps, 'children'>
}

export const ConfirmingButton = forwardRef(function ConfirmingButton(
  {
    buttonName,
    buttonSize = 'medium',
    message,
    icon,
    placeholder,
    confirmToken,
    children,
    onClick,
    fullWidth = true,
    TooltipProps,
    ...rest
  }: ConfirmingButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { handle } = useApiError()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    try {
      setOpen(true)
    } catch (error) {
      handle(error)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOk = (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setOpen(false)
      onClick?.(event)
    } catch (error) {
      handle(error)
    }
  }

  return (
    <Tooltip {...(TooltipProps || { title: '' })}>
      <span>
        {icon ? (
          <RcIconButton
            ref={ref}
            onClick={handleClick}
            icon={icon}
            TooltipProps={children ? { title: <>{children}</> } : undefined}
            disabled={rest.disabled}
            color={rest.color as any}
            size={buttonSize}
            {...rest}
          />
        ) : (
          <RcButton
            size={buttonSize}
            sx={{ height: '100%' }}
            fullWidth={fullWidth}
            {...rest}
            ref={ref}
            onClick={handleClick}
          >
            {children}
          </RcButton>
        )}
        <ConfirmationDialog
          id={rest.id}
          ok={buttonName}
          message={message || <RcTrans i18nKey='shared.confirmation-message' />}
          placeholder={placeholder}
          confirmToken={confirmToken}
          open={open}
          onCancel={handleCancel}
          onOk={handleOk}
        />
      </span>
    </Tooltip>
  )
})
